@import "../../styles/base";

.main {
  box-sizing: border-box;
}

.halfInput {
  display: flex;
  justify-content: space-between;

  & > div {
    flex-grow: 2;

    &:last-of-type {
      margin-left: 30px;
    }
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;

    & > div:last-of-type {
      margin-left: 0;
    }
  }
}

.pay {
  text-align: center;

  button {
    padding-left: 50px;
    padding-right: 50px;
  }

  margin-bottom: 30px;
}
