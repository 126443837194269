@import "../../styles/base";

.main {
  display: inline-block;
  background-color: $white;
  border-radius: 8px;
  position: relative;
  height: 56px;
  width: 100%;
  box-sizing: border-box;
  border: solid 1px #CECECE;
  box-shadow: 0 0 0 0 $blueGlow;
  transition: border 0.3s, box-shadow 0.3s, background-color 0.3s;
  margin-bottom: 16px;
  overflow: hidden;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
    background-color: #F0F2FF;
  }

  &.focused.focused {
    border: solid 2px $highlightBlue;
    box-shadow: 0 8px 16px 0 $blueGlow;
    background-color: $white;
  }

  &.errored.errored {
    border: solid 1px $spRed;
    box-shadow: none;
    background-color: #FFCCCC;

    &.focused {
      border: solid 2px $highlightBlue;
      box-shadow: 0 8px 16px 0 $blueGlow;
    }
  }
}

.fob {
  position: absolute;
  top: 7px;
  right: -50px;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  transition: right 0.3s;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.errored {
    right: 10px;
  }
}

.input {
  border: 0;
  font-size: 16px;
  color: #626262;
  background-color: transparent;
  padding: 18px 20px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  transition: padding 0.3s;
  &:focus {
    outline: none;
  }

  &.active {
    padding: 17px 19px;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    color: $offWhite;
    -webkit-text-fill-color: $offWhite;
    transition: background-color 5000s ease-in-out 0s;
  }

  @include placeholder {
    font-size: 16px;
    font-style: italic;
    color: #626262;
    opacity: 0.4;
    font-weight: 500;
  }
}

.errorMessage {
  color: #626262;
  font-size: 16px;
  margin: -8px 0 16px;
  padding: 0;
  display: flex;
  align-items: flex-start;

  p {
    flex-grow: 1;
  }

  &.errored img {
    display: inline-block;
  }
}

.label {
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  padding: 0 0 8px 8px;
  display: block;
  text-align: left;
}