@import "../../styles/base";

.main {
  padding: 10px 16px;
  border-radius:20px;
  display:inline-block;
  box-sizing: border-box;
  border: solid 2px $secondaryBorder;
  height: 40px;
  margin-left: 5px;
  background-color: rgba(255,255,255,0);
  color: $secondaryText;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  opacity: 1;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(255,255,255,0.06);
  }
  &:focus {
    outline: none;
  }
  &.disabled {
    opacity: 0.5;
  }
}