.logout {
  position: absolute;
  background: transparent;
  border: 0;
  right: 20px;
  top: 20px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.8;
  }
}