@import "../../styles/base";

.main {
  display: flex;
  max-width: 1110px;
  width: 100%;
  margin: 40px auto 0;
  padding-bottom: 70px;
  justify-content: flex-start;
  position: relative;

  .content {
    flex-grow: 1;
    color: $darkText;

    .paymentModule {
      display: block;
      width: 100%;
      background-color: white;
      box-shadow: 0 4px 24px 0 rgba(0,0,0,0.05);
      border-radius: 4px;
      overflow: hidden;
      padding: 30px 50px;
      box-sizing: border-box;
      margin-top: 30px;
      position: relative;

      h1 {
        font-size: 32px;
        text-align: center;
        margin-bottom: 40px;
      }

      .disabledMessage {
        content: 'Vefiry Yuur email above first';
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #979797;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        position: absolute;
        z-index: 10;
        display: none;

        &.disabled {
          display: block;
        }

        img {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 30px;
          @include animation(0, 2s, spBouce, ease);
          animation-iteration-count: infinite;

          @include media-breakpoint-down(sm) {
            transform: translate(-50%, -20px);
          }
        }
      }

      &.disabled {
        &::after {
          content: '';
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          position: absolute;
          background-color: rgba(255,255,255,0.9);
        }
      }

      @include media-breakpoint-down(md) {
        padding: 30px;
      }
    }

    .alreadyOrdered {
      font-size: 16px;
      margin-top: -20px;
      margin-bottom: 20px;
    }

    .statusCheck {
      margin-top: -20px;
      margin-bottom: 20px;
    }
  }

  .sidebar {
    width: 350px;
    min-width: 350px;
  }

  @include media-breakpoint-down(md) {
    margin-top: 0;
    flex-direction: column-reverse;

    .content {
      box-shadow: none;
      border-radius: 0;
      background-color: $whiteBackground;
    }

    .sidebar {
      width: auto;
      min-width: auto;
    }
  }
}


.modal {
  text-align: center;
  padding-bottom: 10px;
  h2 {
    color: #555C74;
    font-weight: 700;
    font-size: 24px;
    margin-top: 40px;
    padding-bottom: 8px;
  }
}

.closeModal {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 10px;
  top: 13px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
}

.waitlist {
  padding: 40px 20px;
  text-align: center;
  box-sizing: border-box;

  .wSubtitle {
    color: #797979;
    font-size: 24px;
    font-weight: 300;
    margin: 8px 0 6px;
  }

  .wTitle {
    color: #555C74;
    font-size: 24px;
    font-weight: 700;
  }

  p {
    font-size: 16px;
    color: #797979;
    text-align: center;

    a {
      color: $highlightBlue;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

  }
}

@keyframes spBouce {
  0% {
    bottom: 30px;
  }

  30% {
    bottom: 40px;
  }

  70% {
    bottom: 30px;
  }

  100% {
    bottom: 30px;
  }
}