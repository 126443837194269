@import "../../styles/base";

.main {
  background-color: black;
}

.bgGrad {
  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 50%;
    @include vertical-gradient(#252E4D, #070B11);
  }
  position: relative;

}

.container {
  box-sizing: border-box;
}


.joinNow, .mainJoin {
  display: inline;
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  box-sizing: border-box;
  position: relative;
  padding: 13px 26px;
  height: 48px;
  border-radius: 24px;
  z-index: 100;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.39);
  opacity: 1;
  transition: color 0.3s;

  @include horizontal-gradient(#F58044, #F2599B);

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    background-color: #CB2244;
    z-index: -1;
    transition: opacity 0.3s;
    opacity: 0;
    border-radius: 100px;
  }

  &:hover {
    color: rgba(255, 255, 255, 0.4);
    &::before {
      opacity: 1;
    }
  }

  @include media-breakpoint-down(sm) {
    font-size: 14px;
    height: 44px;
    padding: 13px 32px;
  }
}

.nav {
  z-index: 200;
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  color: $paleBlue;
  font-weight: 700;
  font-size: 21px;

  .followBanner {
    display: none;
    padding: 18px;
    background-color: rgba(32, 33, 48, 0.98);
    position: relative;
  }

  .stickBanner {
    text-align: center;
    background-color: rgba(29, 45, 94, 0.98);
    color: #B9C3DE;
    font-size: 16px;
    font-weight: 500;
    padding: 21px;
  }

  .navPad {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    display: block;
    position: relative;
  }

  &.follow {
    position: fixed;

    .followBanner {
      display: block;
    }

    .joinNow {
      top: -2px;
      right: 0;
    }

    @include media-breakpoint-down(md) {
      bottom: 0;
      top: auto;
    }
  }

  .preNav {
    text-transform: uppercase;
    color: #A2B1DC;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 2px;
  }

  .longNav {
    display: block;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .shortNav {
    display: none;
    @include media-breakpoint-down(md) {
      display: block;
      text-align: left;
    }
  }

  .joinNow {
    position: absolute;
    right: 140px;
    top: 100px;
  }

  @include media-breakpoint-down(md) {
    &.follow .stickBanner {
      display: none;
    }

    .joinNow {
      top: 20px;
    }
  }

  @include media-breakpoint-down(sm) {
    .joinNow.joinNow {
      top: -3px;
      font-size: 14px;
      height: 40px;
      border-radius: 20px;
      padding-top: 11px;
    }

    .shortNav {
      font-size: 16px;
    }
    .preNav {
      font-size: 12px;
    }

    .stickBanner button {
      margin-top: 10px;
    }
  }
}

.jumbo {
  position: relative;
  padding-top: 70px;

  .welcomeText {
    position: relative;
    width: 130%;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  h2 {
    font-size: 39px;
    font-weight: 700;
    margin: 0 0 5px;
  }
  h1 {
    font-size: 57px;
    font-weight: 700;
    margin: 0;
    letter-spacing: 5px;
  }

  .mainJoin {
    margin-top: 23px;
    display: inline-block;

    &.mjLarge {
      position: absolute;
      right: 0;
      top: 50px;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &.mjSmall {
      display: none;
      margin-left: 100px;
      @include media-breakpoint-down(md) {
        display: inline-block;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    width: 100%;

    h2 {
      font-size: 40px;
    }
  }

  @include media-breakpoint-down(md) {
    h2 {
      font-size: 18px;
    }
    h1 {
      font-size: 38px;
    }

    h1, h2, h3 {
      text-align: center;
      margin-left: 0;
    }

    .col-md-5 {
      position: unset;
    }

    & > div {
      width: 100%;
      flex: auto;
      max-width: 100%;
    }

  }

  @include media-breakpoint-down(sm) {
    width: calc(100% + 30px);
    h1, h2, h3 {
      text-align: left;
      margin-left: 100px;
    }
  }

}

.videoArea {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(md) {
    flex-direction: column-reverse;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 20px;
  }

  .videoThumb {
    cursor: pointer;
    width: 100%;
    padding-top: 36px / 64px * 100%;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 9px;
    box-shadow: 0 36px 52px 0 rgba(0, 0, 0, 0.31);
    margin: 30px 0;
    position: relative;
    text-transform: uppercase;

    .vtTop {
      text-align: center;
      color: white;
      position: absolute;
      right: 30px;
      top: 30px;
      font-weight: 700;
      font-size: 16px;

      img {
        display: block;
        margin: 0 auto 8px;
        width: 44px;
      }
    }

    .vtBottom {
      font-weight: 800;
      font-style: italic;
      color: white;
      opacity: 0.85;
      position: absolute;
      left: 45px;
      bottom: 45px;
      text-align: left;
      font-size: 29px;
    }

    @include media-breakpoint-down(sm) {
      .vtTop {
        img {
          width: 22px;
        }
        font-size: 12px;
      }

      .vtBottom {
        font-size: 16px;
        left: 20px;
        bottom: 20px;
      }
    }
  }
}

.intro {
  text-align: left;
  color: #A2B1DC;
  font-weight: 600;

  .introTop {
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 6px;
  }
  .introMid {
    color: #D7E3FA;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 1.8px;
    margin: 20px 0 18px 0;
  }

  .introBottom {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 5.25px;
    margin-bottom: 20px;
  }

  @include media-breakpoint-down(sm) {
    text-align: center;

    .introTop {
      font-size: 10px;
    }

    .introMid {
      font-size: 18px;
    }

    .introBottom {
      font-size: 10px;
    }

    img {
      width: 50%;
    }
  }
}

.jumboBottom {
  text-align: center;
  margin-top: -100px;
  padding-bottom: 200px;
  position: relative;

  .jbPreSub {
    color: $lightBlue;
    opacity: 0.44;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 24px;
    letter-spacing: 7px;
  }
  .jbSubtitle {
    color: $white;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 34px;
    letter-spacing: 7px;
    opacity: 1;
    margin: 30px 0 20px;
  }
  .jbTitle {
    font-size: 64px;
    color: $lightBlue;
    letter-spacing: 3px;
    font-weight: 600;
    font-style: italic;
    opacity: 1;
  }

  @include media-breakpoint-down(lg) {
    margin-top: 40px;
    margin-bottom: 50px;
  }

  @include media-breakpoint-down(md) {
    margin-top: 100px;
    padding-bottom: 150px;

    .jbPreSub {
      font-size: 14px;
    }
    .jbSubtitle {
      font-size: 16px;
      margin: 8px 0;
    }
    .jbTitle {
      font-size: 24px;
    }
  }
}

.jumboPutt {
  margin-top: 20px;
  width: 110%;

  @include media-breakpoint-down(md) {
    width: 145px;
    position: absolute;
    left: calc(20% - 130px);
    top: 50px;
  }

  @include media-breakpoint-down(sm) {
    left: -20px;
    top: 35px;
  }
}

.waves {
  width: 100%;
  height: 1041px;
  position: absolute;
  left: 0;
  bottom: -0px;
  z-index: 0;

  @include media-breakpoint-down(sm) {
    width: 200%;
    left: -100%;
    height: 800px;
  }
}

.blackSection {
  min-height: 500px;
  width: 100%;
  margin-top: -300px;
  position: relative;
  box-sizing: border-box;

  &.visible {
    .breakDownSection {
      opacity: 1;
      transform: translateY(0px);

      &.bdTwo {
        transform: translateY(-50%);
      }
    }
  }

  .putterBreakdown {
    width: 40%;
    height: auto;
    margin: 0 auto;
    display: block;

    @include media-breakpoint-down(md) {
      width: 90%;
    }
  }

  .breakDownSection {
    opacity: 0;
    transform: translateY(30px);
    width: 340px;
    position: absolute;

    transition: opacity 0.5s, transform 0.5s;

    h2 {
      color: $white;
      font-size: 48px;
      font-weight: 800;
    }
    p {
      font-size: 16px;
      color: #A1B2B2;
    }
    line-height: 27px;

    @include media-breakpoint-down(md) {
      position: relative;
      width: 100% !important;
      left: auto !important;
      right: auto !important;
      top: auto !important;
      bottom: auto !important;
      transform: none !important;
      margin: 16px 0;
      overflow: hidden;

      h2 {
        font-size: 32px !important;
      }
      p {
        font-size: 18px !important;;
      }

      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }

    @include media-breakpoint-down(lg) {
      width: 300px;
      h2 {
        font-size: 30px;
      }
      p {
        font-size: 16px;
      }
    }
  }

  .bdOne {
    right: 0;
    top: 100px;

  }
  .bdTwo {
    left: 0;
    top: calc(50% + 70px);
    transform: translateY(calc(-50% + 30px));
    transition-delay: 0.5s;

    @include media-breakpoint-up(md) {
      &::after {
        content: '';
        position: absolute;
        top: 90px;
        left: 90%;
        width: 160px;
        height: 2px;
        background-color: white;
        opacity: 0.3;
      }
    }

    @include media-breakpoint-down(lg) {
      &::after {
        width: 120px;
        top: 70px;
        left: 60%;
      }
    }
  }
  .bdThree {
    right: 20px;
    bottom: 20px;
    transition-delay: 1s;

    @include media-breakpoint-up(md) {
      &::before {
        content: '';
        position: absolute;
        top: 120px;
        right: 110%;
        width: 220px;
        height: 2px;
        background-color: white;
        opacity: 0.3;
      }
    }

    @include media-breakpoint-down(lg) {
      &::before {
        right: 105%;
        width: 130px;
        top: 60px;
      }
    }
  }
}

.seasonSection {

  &.visible {
    .ltTimeline {
      div > span {
        width: 100%;
      }
    }

    .ltAnnotations > div {
      opacity: 1;

      &::before {
        height: 130px;
      }
    }
  }

  @include horizontal-gradient(#373B57, #191B2E);
  width: 100%;
  position: relative;
  padding-bottom: 177px;

  @include media-breakpoint-down(md) {
    padding-bottom: 50px;
  }

  &::before {
    content: '';
    width: 100%;
    position: absolute;
    height: 200px;

    @include vertical-gradient(black, rgba(0, 0, 0, 0))
  }

  h1 {
    text-align: center;
    color: white;
    font-size: 48px;
    font-weight: 800;
    padding-top: 160px;
    margin-bottom: 64px;

    @include media-breakpoint-down(md) {
      font-size: 32px;
      padding: 80px 30px 0;
      position: relative;
      margin-bottom: 30px;
    }
  }

  .locationTypeLarge {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .locationTypeSmall {
    display: none;
    @include media-breakpoint-down(md) {
      display: block;
    }
    text-align: center;

    .ltSeason {
      color: rgba(255, 255, 255, 0.81);
      font-size: 16px;
      margin-top: 35px;
      margin-bottom: 20px;
    }

    .ltEvent {
      font-weight: 800;
      font-size: 24px;
      margin-bottom: 10px;
      padding: 0 30px;
      box-sizing: border-box;
    }

    & > div {
      border-radius: 2px;
      position: relative;
      padding-bottom: 32px;
      overflow: hidden;

      &::before {
        content: '';
        display: block;
        width: 6px;
        border-radius: 2px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #00E097;
      }

      &::after {
        content: 'Online in VR';
        position: absolute;
        left: -30px;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        display: block;
        font-weight: 700;
        font-size: 16px;
      }
    }

    .ltVR {
      background-color: rgba(0, 224, 151, 0.1);
      margin-bottom: 16px;
    }

    .ltLive {
      background-color: rgba(0, 110, 221, 0.1);

      &::before {
        background-color: #006EDD;
      }

      &::after {
        content: 'Live Las Vegas';
        left: -40px;
      }
    }
  }

  .ltSteps {
    border-radius: 6px;
    font-size: 24px;
    font-weight: 800;
    background-color: #19335A;
    height: 155px;
    overflow: hidden;
    display: flex;
    align-items: stretch;

    @include media-breakpoint-down(lg) {
      font-size: 18px;
    }

    & > div {
      padding: 0;
      position: relative;
      box-sizing: border-box;

      & > span {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        position: absolute;
        opacity: 0.8;
      }
    }

    .ltStepOne {
      text-align: left;
      background-color: #0D9D82;
      width: calc(25% - 35px);
      z-index: 10;
      &::after {
        background-color: #0D9D82;
      }
      span {
        padding-left: 32px;
      }
    }

    .ltStepTwo {
      text-align: center;
      width: 50%;
      background-color: #155768;
      z-index: 5;
      &::after {
        background-color: #155768;
      }
      span {
        padding-left: 140px;
        padding-right: 70px;
      }
    }

    .ltStepThree {
      text-align: right;
      width: calc(25% + 35px);
      span {
        padding-right: 32px;
        padding-left: 32px;
      }
    }

    .ltStepOne, .ltStepTwo {
      &::after {
        content: '';
        width: 110px;
        height: 110px;
        position: absolute;
        right: -55px;
        top: 22.33px;
        transform-origin: center center;
        transform: rotate(45deg);
        z-index: -1;
      }
    }
  }

  .ltTimeline {
    width: 100%;
    display: flex;
    color: white;
    font-weight: 700;
    font-size: 1.667em;
    margin-bottom: 8px;
    align-items: flex-end;

    & > div > span {
      display: block;
      width: 0;
      height: 6px;
      border-radius: 3px;
      margin-top: 8px;
      transition: width 1s;
    }

    .ltTlOne {
      width: 75%;
      span {
        background-color: #00E097;
      }
    }

    .ltTlTwo {
      text-align: right;
      width: 24%;
      margin-left: 1%;
      span {
        background-color: #006EDD;
        transition-delay: 1s;
      }
    }
  }

  .ltAnnotations {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 1.5em;
    color: rgba(255, 255, 255, 0.81);

    & > div {
      padding-top: 90px;
      position: relative;
      opacity: 0;
      transition: opacity 0.8s;

      &:nth-child(2) {
        &::before {
          transition-delay: 1s;
        }
      }
      &:nth-child(3) {
        &::before {
          transition-delay: 1.5s;
        }
      }

      &::before {
        content: '';
        width: 2px;
        height: 0;
        @include vertical-gradient(transparent, #66B8EA);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 30px;
        z-index: 100;
        transition: height 0.5s;
        transition-delay: 0.5s;
      }
      &::after {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: #66B8EA;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 30px;
        z-index: 101;
      }
    }
  }

}

.quoteSection {
  width: 100%;
  height: 560px;
  background: no-repeat fixed center;
  background-size: cover;
  overflow: hidden;
  position: relative;

  @include media-breakpoint-down(lg) {
    height: 500px;
  }

  @include media-breakpoint-down(md) {
    height: 420px;
  }

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  h1 {
    font-size: 42px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.57);
    width: 80%;
    margin-left: 10%;
    text-align: left;
    margin-bottom: 20px;

    span {
      color: white;
    }
  }

  .quoteCredit {
    font-size: 32px;
    color: $yellow;
    font-weight: 800;
    font-style: italic;
    margin-bottom: 16px;
  }

  .quoteInfo {
    font-weight: 800;
    font-style: italic;
    opacity: 0.5;
    font-size: 24px;
  }

  @include media-breakpoint-down(md) {
    h1 {
      font-size: 22px;
      line-height: 30px;
    }

    .quoteCredit {
      font-size: 26px;
    }

    .quoteInfo {
      font-size: 18px;
    }
  }
}

.analyzerSection {
  background-size: cover;
  width: 100%;
  overflow: hidden;

  &.visible {
    .analyzerPreview {
      img {
        left: 0;
        opacity: 1;
      }
    }
  }

  .container > h3 {
    margin-top: 77px;
    margin-bottom: 16px;
    text-align: center;
  }

  .reverseRow {
    margin-top: 71px;
    margin-bottom: 150px;
    display: flex;

    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
      margin-bottom: 50px;
    }
  }

  h3 {
    color: #4B5179;
    font-size: 24px;
    margin: 0;

    @include media-breakpoint-down(md) {
      font-size: 24px;
    }
  }

  h1 {
    margin: 0;
    font-weight: 800;
    color: #2D458B;
    font-size: 48px;
    text-align: center;
  }

  h2 {
    color: #2D458B;
    font-size: 2.667em;
    font-weight: 800;
    margin: 0;
  }

  p {
    color: #555A5A;
    line-height: 27px;
    font-size: 16px;

    @include media-breakpoint-down(md) {
      width: 100%;
      font-size: 20px;
      line-height: 30px;
    }
  }

  .analyzerInfo {
    width: 40%;
    margin-top: 60px;
    padding: 0 20px;
    box-sizing: border-box;

    h2 {
      margin: 11px 0;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .analyzerPreviewContainer {
    width: 60%;
    padding: 0 20px;
    box-sizing: border-box;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .analyzerPreview {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    img {
      width: calc(33.33% - 9px);
      position: relative;
      left: 50px;
      opacity: 0;
      transition: left 1s, opacity 1s;
      height: 100%;
    }

    img:first-of-type {
      width: 100%;
      margin-bottom: 13px;
    }

    img:nth-of-type(2) {
      transition-delay: 0.3s;
    }
    img:nth-of-type(3) {
      transition-delay: 0.6s;
    }
    img:nth-of-type(4) {
      transition-delay: 0.9s;
    }

    @include media-breakpoint-down(md) {
      margin: -40px 0 -20px;
      width: 100%;
      box-sizing: border-box;
    }
  }
}

.trackAndImproveSection {
  width: 100%;
  overflow: visible;
  position: relative;
  z-index: 10;

  &.visible {
    .drillDays.drillDays img {
      left: 0;
      opacity: 1;
    }
  }

  .drillOver {
    width: 100%;
    position: relative;
    bottom: -50px;
  }

  @include horizontal-gradient(#212339, #20214C);

  h2 {
    color: #ACCDE0;
    text-align: center;
    font-size: 34px;
    font-weight: 300;
    padding-top: 73px;
    margin: 0;

    @include media-breakpoint-down(md) {
      font-size: 28px;
    }
  }

  .taiOptions {
    z-index: 100;
    display: flex;
    width: 575px;
    justify-content: space-between;
    position: relative;
    margin: 38px auto 0;

    button {
      font-weight: 600;
      font-size: 26px;
      color: #B4C1E3;
      border: 0;
      background-color: transparent;
      cursor: pointer;

      transition: color 0.3s ease;

      &:focus {
        outline: none;
      }
      &.current {
        color: white;
      }
    }

    span {
      position: absolute;
      display: block;
      height: 9px;
      border-radius: 2px;
      bottom: -20px;

      transition: left 0.5s, width 0.5s;

      @include horizontal-gradient(#EF4E36, #F2599B);

      &.first {
        width: 155px;
        left: 0px;
      }
      &.second {
        width: 102px;
        left: 265px;
      }
      &.third {
        left: calc(100% - 100px);
        width: 100px;
      }
    }

    @include media-breakpoint-down(md) {
      width: calc(100% - 60px);
      margin: 30px 0 0 30px;

      button {
        font-size: 20px;
      }

      span {
        height: 6px;
        bottom: -10px;

        &.first {
          width: 125px;
          left: 0;
        }
        &.second {
          width: 80px;
          left: 297px;
        }
        &.third {
          left: calc(100% - 81px);
          width: 80px;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      span {
        &.second {
          width: 80px;
          left: calc(50% - 20px);
        }
      }
    }
  }

  .taiLines {
    position: relative;
    svg {
      position: absolute;
      left: 0;
      width: 100%;
      top: 0;

      path {
        stroke-dasharray: 1800;
        stroke-dashoffset: 1800px;
        @include animation(0s, 1s, dash);

        animation-timing-function: ease;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;

        &.pathFill {
          fill-opacity: 0;
          @include animation(1.6s, 1s, fade);
        }
      }

      &:nth-of-type(2) {
        path {
          @include anim-delay(0.2s)
        }
      }
      &:nth-of-type(3) {
        path {
          @include anim-delay(0.4s)
        }
      }
      &:nth-of-type(4) {
        path:last-of-type {
          @include anim-delay(0.6s)
        }
      }
    }
  }

  .drills {
    margin-top: 200px;

    h3 {
      margin: 0;
      font-weight: 800;
      font-style: italic;
      color: #46F8DE;
      letter-spacing: 3.5px;
      font-size: 35px;
    }

    p {
      color: #7B7C94;
      letter-spacing: 2.5px;
      font-weight: 600;
      font-style: italic;
      font-size: 18px;
    }

    @include media-breakpoint-down(md) {
      h3 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }
    }

    @include media-breakpoint-down(sm) {
      h3 {
        text-align: center;
        padding: 0 20px;
        box-sizing: border-box;
      }

      p {
        text-align: center;
        padding: 0 20px;
        box-sizing: border-box;
        margin-bottom: 50px;
      }
    }

    .drillDays {
      position: relative;
      height: 369px;

      img {
        position: absolute;
        width: 369px;
        margin-left: 16px;
        box-shadow: 0 -16px 80px 0 rgba(0, 0, 0, 0.13);
        left: 0;
        top: 0;
        opacity: 0;
        height: 100%;
        transition: left 0.5s ease, top 0.5s ease, opacity 0.5s;
        z-index: 10;

        &:nth-of-type(1) {
          &.state1 {
            left: -200px;
            top: 200px;
            opacity: 0;
          }

          &.state2 {
            left: 64px;
            top: -64px;
            opacity: 0;
            z-index: 4;
          }

          &.state3 {
            left: 48px;
            top: -48px;
            opacity: 0.1;
            z-index: 4;
          }

          &.state4 {
            left: 32px;
            top: -32px;
            opacity: 0.2;
            z-index: 4;
          }

          &.state5 {
            left: 16px;
            top: -16px;
            opacity: 0.7;
            z-index: 4;
          }
        }

        &:nth-of-type(2) {
          z-index: 9;
          left: 16px;
          top: -16px;
          opacity: 0.7;

          &.state1 {
            left: 0;
            top: 0;
            opacity: 1;
          }

          &.state2 {
            left: -200px;
            top: 200px;
            opacity: 0;
          }

          &.state3 {
            left: 64px;
            top: -64px;
            opacity: 0;
            z-index: 3;
          }

          &.state4 {
            left: 48px;
            top: -48px;
            opacity: 0.1;
            z-index: 3;
          }

          &.state5 {
            left: 32px;
            top: -32px;
            opacity: 0.2;
            z-index: 3;
          }
        }

        &:nth-of-type(3) {
          z-index: 8;
          left: 32px;
          top: -32px;
          opacity: 0.2;

          &.state1 {
             left: 16px;
             top: -16px;
             opacity: 0.7;
           }

          &.state2 {
            left: 0;
            top: 0;
            opacity: 1;
          }

          &.state3 {
            left: -200px;
            top: 200px;
            opacity: 0;
          }

          &.state4 {
            left: 64px;
            top: -64px;
            opacity: 0;
            z-index: 2;
          }

          &.state5 {
            left: 48px;
            top: -48px;
            opacity: 0.1;
            z-index: 2;
          }
        }
        &:nth-of-type(4) {
          z-index: 7;
          left: 48px;
          top: -48px;
          opacity: 0.1;

          &.state1 {
            left: 32px;
            top: -32px;
            opacity: 0.2;
          }

          &.state2 {
            left: 16px;
            top: -16px;
            opacity: 0.7;
          }

          &.state3 {
            left: 0;
            top: 0;
            opacity: 1;
          }

          &.state4 {
            left: -200px;
            top: 200px;
            opacity: 0;
          }

          &.state5 {
            left: 64px;
            top: -64px;
            opacity: 0;
          }
        }

        &:nth-of-type(5) {
          z-index: 6;
          left: 64px;
          top: -64px;
          opacity: 0;

          &.state1 {
            left: 48px;
            top: -48px;
            opacity: 0.1;
          }

          &.state2 {
            left: 32px;
            top: -32px;
            opacity: 0.2;
          }

          &.state3 {
            left: 16px;
            top: -16px;
            opacity: 0.7;
          }

          &.state4 {
            left: 0;
            top: 0;
            opacity: 1;
          }

          &.state5 {
            left: -200px;
            top: 200px;
            opacity: 0;
          }
        }

        &:nth-of-type(6) {
          z-index: 5;
          left: -200px;
          top: 200px;
          opacity: 0;

          &.state1 {
            z-index: 1;
            left: 64px;
            top: -64px;
            opacity: 0;
          }

          &.state2 {
            left: 48px;
            top: -48px;
            opacity: 0.1;
          }

          &.state3 {
            left: 32px;
            top: -32px;
            opacity: 0.2;
          }

          &.state4 {
            left: 16px;
            top: -16px;
            opacity: 0.7;
          }

          &.state5 {
            left: 0;
            top: 0;
            opacity: 1;
          }
        }

        @include media-breakpoint-down(md) {
          width: 300px;
        }

        @include media-breakpoint-down(sm) {
          width: 250px;
        }
      }
    }
  }
}

.readySection {
  @include horizontal-gradient(#20729A, #0F7653);

  overflow: hidden;
  background-position: bottom center;

  position: relative;
  padding: 90px 0;
  text-align: center;

  img {
    height: 90%;
    width: auto;
    position: absolute;
    left: calc(50% + 50px);
    transform: translateX(-50%);
    bottom: 0;
    z-index: 0;

    @include media-breakpoint-down(md) {
      font-size: 50px;
      height: 80%;
      left: calc(50% + 30px);
    }
  }

  h1 {
    text-align: center;
    font-size: 112px;
    font-weight: 800;
    font-style: italic;
    z-index: 100;
    position: relative;
    margin-bottom: 50px;
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
      font-size: 50px;
    }
  }

  .joinReady {
    display: block;
    color: white;
    text-decoration: none;
    box-sizing: border-box;
    font-size: 1.5em;
    font-weight: 600;
    padding: 14px 26px;
    height: 50px;
    border-radius: 25px;
    z-index: 100;
    position: relative;
    width: 180px;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.39);
    transition: color 0.3s;
    margin: 0 auto;

    @include horizontal-gradient(#F58044, #F2599B);

    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      background-color: #CB2244;
      z-index: -1;
      transition: opacity 0.3s;
      opacity: 0;
      border-radius: 100px;
    }

    &:hover {
      color: rgba(255, 255, 255, 0.4);
      &::before {
        opacity: 1;
      }
    }
  }
}

.footer {
  overflow: hidden;
  @include horizontal-gradient(#373B57, #191B2E);

  .fBanner {
    width: 100%;
    background-color: rgba(21, 21, 21, 0.4);
    color: #99A2B3;
    font-size: 18px;
    font-weight: 600;
    padding: 26px;
    text-align: center;
    box-sizing: border-box;

    @include media-breakpoint-down(md) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .footerLogos {
    .fLogosTitle {
      text-align: center;
      font-size: 1.5em;
      color: #99A2B3;
      margin: 78px 0 29px;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 5px;
    }

    .fLogoImages {
      display: flex;
      height: 68px;
      justify-content: center;

      img {
        margin: 8px;
      }
    }
  }

  .footerLinks {
    width: 90%;
    margin-left: 5%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 16px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 50px;
    }

    a, button {
      border: 0;
      background-color: transparent;
      color: #7E85B1;
      font-weight: 700;
      letter-spacing: 2.5px;
      text-transform: uppercase;
      margin: 10px;
      text-decoration: none;
      opacity: 1;
      font-size: 1.5em;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .footerCopyright {
    text-align: center;
    color: #A7ADD6;
    font-size: 1.5em;
    margin-bottom: 50px;

    @include media-breakpoint-down(md) {
      margin-bottom: 100px;
    }
  }
}

.modal {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  overflow: hidden;
  width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}


@keyframes fade {
  to {
    fill-opacity: 1;
  }
}