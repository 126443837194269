@import "../../../styles/base";

.main {
  span {
    color: #626262;
    font-size: 16px;
    font-weight: 500;
    padding: 0 0 8px 8px;
    display: block;
  }
}

.cardInput {
  display: inline-block;
  background-color: $white;
  border-radius: 8px;
  position: relative;
  height: 56px;
  width: 100%;
  box-sizing: border-box;
  border: solid 1px #CECECE;
  box-shadow: 0 0 0 0 $blueGlow;
  transition: border 0.3s, box-shadow 0.3s, background-color 0.3s;
  margin-bottom: 16px;
  overflow: hidden;
  padding: 18px 20px;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
    background-color: #F0F2FF;
  }

  &.focused.focused {
    border: solid 2px $highlightBlue;
    box-shadow: 0 8px 16px 0 $blueGlow;
    background-color: $white;
  }

  &.errored.errored {
    border: solid 1px $spRed;
    box-shadow: none;
    background-color: #FFCCCC;

    &.focused {
      border: solid 2px $highlightBlue;
      box-shadow: 0 8px 16px 0 $blueGlow;
    }
  }
}

.errorMessage {
  color: #626262;
  font-size: 16px;
  margin: -8px 0 16px;
  padding: 0;
  display: flex;
  align-items: flex-start;

  p {
    flex-grow: 1;
  }
}