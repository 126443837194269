@import "../../styles/base";

.modal {
  text-align: center;
  padding-bottom: 10px;
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
  display: block;
  h2 {
    color: #555C74;
    font-weight: 700;
    font-size: 24px;
    margin-top: 40px;
    padding-bottom: 8px;
  }

  button {
    margin-top: 0px;
    margin-bottom: 20px;
  }

}

.closeModal {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 10px;
  top: 13px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
}