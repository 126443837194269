@import "../../styles/base";

.main {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  color: #8DCBDB;
  background-color: $blue;
  padding: 14px 32px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  box-sizing: border-box;
}