$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1280px
) !default;


.row > div {
  box-sizing: border-box;
}