@import "../../styles/base";

.priceModule {
  background-color: white;
  box-shadow: 0 4px 24px 0 rgba(0,0,0,0.05);
  border-radius: 4px;
  overflow: hidden;
  height: auto;
  color: $darkText;
  padding: 40px 38px;
  box-sizing: border-box;
  margin-bottom: 30px;

  @include media-breakpoint-down(md) {
    margin-left: 0;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 26px;
    font-weight: 600;
    margin: 0 0 16px;
  }

  .breakDownItem {
    border-bottom: solid 1px #EEEEEE;
    padding: 12px 0;
    color: $grayText;
    font-size: 14px;
    display: flex;
    justify-content: space-between;

    &:last-of-type {
      font-weight: 600;
      color: $darkText;
      border-bottom: 0;
      font-size: 16px;
      position: relative;
    }
  }

  .details {
    background-color: rgba(206, 213, 251, 0.16);
    border: solid 1px #DFE2F0;
    border-radius: 4px;
    padding: 8px;
    text-align: center;
    margin-top: 8px;
    font-size: 14px;
    color: $grayText;
    font-weight: 500;

    &.canceled {
      border-color: $brightRed;
      background-color: rgba(255, 76, 76, 0.16);
    }

    .boldDetail {
      font-weight: 700;
      font-size: 16px;
    }

    div {
      margin: 8px;
    }
  }

  .orderDate {
    font-style: italic;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    color: #626262;
    margin-top: 8px;
  }

  .status {
    font-weight: 700;
    color: $blackText;
  }

  .statusIcon {
    position: relative;
    top: -2px;
    vertical-align: middle;
    margin-left: 5px;
  }
}

.cancelation {
  margin-top: 20px;
  margin-bottom: -15px;
  text-align: center;
  color: #797979;

  button {
    display: inline-block;
    border: 0;
    background: transparent;
    text-decoration: underline;
    color: #797979;

    &:hover {
      text-decoration: none;
    }
  }
}


.modal {
  text-align: center;
  padding-bottom: 10px;
  h2 {
    color: #555C74;
    font-weight: 700;
    font-size: 24px;
    margin-top: 40px;
    padding-bottom: 8px;
  }

  textarea {
    font-size: 16px;

    @include placeholder {
      color: #aaaaaa;
      font-size: 16px;
    }

    &:focus {
      outline: none;
    }
  }
}

.reasonInput {
  width: 100%;
  border-radius: 4px;
  border: solid 1px #CECECE;
  padding: 20px;
  box-sizing: border-box;
  resize: vertical;
}

.closeModal {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 10px;
  top: 13px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
}