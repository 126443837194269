@import "../../styles/base";

.mobilePriceModule {
  z-index: 100;
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 30px 40px;
  background-color: white;
  box-sizing: border-box;
  color: $darkText;
  box-shadow: 0 -8px 32px 0 rgba(0, 0, 0, 0.21);
  font-weight: 600;
  font-size: 24px;

  .continue {
    position: absolute;
    right: 40px;
    top: 21px;

    border: 0;
    display: inline-block;
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    box-sizing: border-box;
    padding: 11px 26px;
    height: 46px;
    border-radius: 26px;
    z-index: 100;
    box-shadow: 0 8px 24px 0 rgba(242, 87, 134, 0.15);
    opacity: 1;

    transition: opacity 0.3s;

    @include horizontal-gradient(#F58044, #F2599B);

    &:hover {
      opacity: 0.8;
    }
  }

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.main {
  display: flex;
  max-width: 1110px;
  width: 100%;
  margin: 40px auto 0;
  padding-bottom: 70px;
  justify-content: flex-start;

  .content {
    flex-grow: 1;
    background-color: white;
    box-shadow: 0 4px 24px 0 rgba(0,0,0,0.05);
    border-radius: 4px;
    overflow: hidden;

    .productHeadContainer {
      position: relative;
      display: block;
    }

    .productHead {
      width: 100%;
      height: auto;
    }

    .phName {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 40px;

      h1 {
        font-size: 39px;
        font-weight: 800;
        letter-spacing: 1.5px;
        margin: 0;
      }

      div {
        opacity: 0.8;
        font-size: 18px;
        font-weight: 500;
        margin-top: 15px;
      }

      @include media-breakpoint-down(sm) {
        h1 {
          font-size: 20px;
        }
        div {
          font-size: 12px;
          margin-top: 5px;
        }
      }
    }

    .contentPadding {
      padding: 40px;
      color: $darkText;
      box-sizing: border-box;

      h1 {
        margin: 0;
        font-size: 32px;
      }
      h2 {

        font-size: 18px;
        margin: 16px 0 0;
        font-weight: 600;
        font-style: italic;

        img {
          position: relative;
          top: 3px;
        }
      }

      .included {
        margin-top: 32px;
        margin-bottom: 48px;
        background-color: rgba(206, 213, 251, 0.26);
        border-radius: 8px;
        min-height: 30px;
        overflow: hidden;

        h3 {
          font-size: 18px;
          color: #606E92;
          text-align: center;
          margin: 24px;
        }

        .includedItems {
          display: flex;
          padding: 0 46px 32px;
          justify-content: space-between;

          .includedItem {
            .includedLogo {
              width: 58px;
              height: 58px;
              border-radius: 29px;
              background-color: white;
              box-shadow: 0 8px 24px 0 rgba(0,0,0,0.09);
              position: relative;
              display: block;
              margin: 0 auto;

              img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
            }
            label {
              text-align: center;
              color: #606E92;
              font-size: 15px;
              width: 80%;
              display: block;
              margin: 12px auto 0;
            }
          }
        }

        @include media-breakpoint-down(sm) {
          background-color: white;
          border: solid 1px #C7CADE;

          h3 {
            text-align: left;
          }

          .includedItems.includedItems {
            flex-direction: column;
            text-align: left;
            margin-top: -20px;

            .includedLogo {
              box-shadow: none;
              width: 28px;
              height: auto;
              display: inline-block;
              margin-top: 20px;

              img {
                position: relative;
                left: 0;
                top: 5px;
                transform: none;
              }
            }
            label {
              display: inline-block;
              width: auto;
              margin: 0 0 0 16px;
              font-size: 16px;
            }
          }
        }
      }

      .tabs {
        .tabOptions {
          border-bottom: solid 1px #E0E0E0;

          .tabOption {
            border: 0;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            margin-bottom: -1px;
            background-color: transparent;
            color: $darkText;
            font-size: 18px;
            padding: 10px 24px;
            box-sizing: border-box;
            margin-left: 8px;
            font-weight: 500;
            cursor: pointer;

            &:focus {
              outline: none;
            }

            &:hover {
              color: $grayText;
            }

            &.selected {
              border: solid 1px #E0E0E0;
              border-bottom: 0;
              background-color: white;

              @include media-breakpoint-down(md) {
                background-color: $whiteBackground;
              }
            }

            @include media-breakpoint-down(sm) {
              font-size: 12px;
              padding: 8px 16px;
            }
          }
        }
        .tabDescription {
          line-height: 28px;
          font-size: 16px;
          font-weight: 300;
          color: $darkText;
          padding: 27px 16px;
          box-sizing: border-box;
          display: none;

          &.visible {
            display: block;
          }
        }
      }
    }
  }

  .sidebar {
    width: 350px;
    min-width: 350px;
  }

  @include media-breakpoint-down(md) {
    margin-top: 0;

    .content {
      box-shadow: none;
      border-radius: 0;
      background-color: $whiteBackground;
    }

    .sidebar {
      display: none;
    }
  }
}

.shroud {
  position: fixed;
  left:0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $background;
}