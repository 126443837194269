@import "../../../styles/base";

.addressTypeahead {
  position: relative;
}

.typeaheadOptions {
  position: absolute;
  top: calc(100% - 5px);
  left: 0;
  background-color: white;
  min-height: 20px;
  padding: 10px 0;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
  z-index: 100;
  font-size: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .typeOption {
    padding: 8px 15px;
    background-color: white;
    transition: background-color 0.3s;
    cursor: pointer;
    &:hover {
      background-color: #eeeeee;
    }
  }

  @include media-breakpoint-down(sm) {
    position: fixed;
    left: 0;
    bottom: 0;
    top: auto;
    width: 100%;
    flex-direction: row;
    overflow: scroll;
    border-radius: 0;
    box-shadow: 0 0 16px 0 rgba(0,0,0,0.1);

    .typeOption {
      height: 20px;
      white-space: nowrap;
      border-right: solid 1px #cccccc;
    }
  }
}