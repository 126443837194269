body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EEEFF5;
  color: white;
  font-size: 12px;
}

button {
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}

button:focus {
  outline: none;
}

input,
textarea,
[contenteditable] {
  caret-color: #192A39;
}


.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}


.ReactModal__Content {
  top: calc(50% + 50px) !important;
  transition: top 300ms ease-in-out;
}

.ReactModal__Content--after-open {
  top: 50% !important;
}

.ReactModal__Content--before-close {
  top: calc(50% + 50px) !important;
}