@import "../../styles/base";

.main {
  border: 0;
  margin-top: 24px;
  display: inline-block;
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  box-sizing: border-box;
  padding: 16px 26px;
  height: 52px;
  border-radius: 26px;
  position: relative;
  box-shadow: 0 8px 24px 0 rgba(242, 87, 134, 0.15);
  z-index: 1;

  transition: color 0.3s;

  @include horizontal-gradient(#F58044, #F2599B);

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    background-color: #CB2244;
    z-index: -1;
    transition: opacity 0.3s;
    opacity: 0;
    border-radius: 100px;
  }

  &:hover {
    color: rgba(255, 255, 255, 0.4);
    &::before {
      opacity: 1;
    }
  }

  &.disabled {
    color: rgba(255, 255, 255, 0.5);

    &:hover::before {
      opacity: 0;
    }
  }
}