@import "../../styles/base";

.view {
  background-color: black;
  min-height: 100vh;
  overflow: hidden;

  h1 {
    margin: 0;
  }
}

.header {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 1110px;
  width: 100%;
  margin: 0 auto;
  padding-top: 40px;

  img {
    margin: 0 40px 0 20px;
    height: 250px;
    width: auto;
  }
  .headerTitle{
    color: $white;
    font-weight: 700;
    font-size: 30px;
    text-align: left;

    h1 {
      font-size: 54px;
      font-weight: 700;
      margin: 0;
    }
  }

  @include media-breakpoint-down(md) {
     img {
       height: 180px
     }
   }

  @include media-breakpoint-down(sm) {
    padding-top: 20px;
    img {
      height: 100px;
      margin: 0 20px;
    }
    .headerTitle {
      font-size: 18px;
      h1 {
        font-size: 30px;
      }
    }
  }
}

.main {
  display: flex;
  max-width: 1110px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 70px;
  justify-content: flex-start;
  position: relative;
  padding-top: 20px;

  .content {
    flex-grow: 1;
    color: $darkText;

    .statusModule {
      display: block;
      width: 100%;
      background-color: white;
      box-shadow: 0 4px 24px 0 rgba(0,0,0,0.05);
      border-radius: 4px;
      overflow: hidden;
      padding: 40px 20% 80px 20%;
      box-sizing: border-box;
      position: relative;

      .statusHead {
        text-align: center;
        font-size: 16px;
        h1 {
          font-size: 32px;
          margin: 0 0 8px;
        }
      }

      .whereToBuy {
        border: solid 1px #DFE2F0;
        background-color: rgba(206, 213, 251, 0.16);
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 20px 14px;
        margin-top: 18px;

        img {
          width: 100%;
          margin-top: 10px;
          margin-right: 5px;
        }

        div {
          font-weight: 600;
          font-size: 18px;
          width: 50%;

          &:last-of-type {
            padding-left: 20px;
          }

          a {
            display: inline-block;
            background-color: white;
            color: #2761E9;
            text-decoration: none;
            font-size: 14px;
            border: solid 1px #DFE2F0;
            border-radius: 4px;
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.09);
            padding: 8px 16px;
            margin-top: 8px;
            opacity: 1;
            transition: opacity 0.3s;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }

      .appInfo {
        display: flex;
        margin-top: 24px;

        .aiIcon {
          margin-right: 14px;

          img {
            width: 90px;
          }
        }
        .aiDescription {
          font-size: 15px;
          h3 {
            font-size: 16px;
            margin-bottom: 4px;
          }

          a {
            display: block;
            margin-top: 4px;
            color: #2761E9;
            font-size: 14px;
            text-decoration: none;
            opacity: 1;
            transition: opacity 0.3s;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }

      .statusStep {
        margin-top: 38px;
        font-size: 16px;
        position: relative;

        h3 {
          margin: 0;
          font-weight: 600;
          font-size: 18px;
        }

        p {
          margin: 8px 0 0 0;
          line-height: 24px;
        }

        &::before {
          content: '';
          top: 0;
          position: absolute;
          left: -60px;
          width: 34px;
          height: 34px;
          display: block;
          border-radius: 17px;
          border: solid 1px #DFE2F0;
          color: #5165A4;
          font-weight: 600;
          font-size: 18px;
          text-align: center;
          padding-top: 4.5px;
          box-sizing: border-box;
        }

        &.stepOne {
          &::before {
            content: '1';
          }
        }

        &.stepTwo {
          &::before {
            content: '2';
          }
        }
      }
    }
  }

  .sidebar {
    width: 350px;
    min-width: 350px;
    margin-left: 30px;

  }

  @include media-breakpoint-down(md) {
    margin-top: 0;
    flex-direction: column-reverse;

    .content {
      box-shadow: none;
      border-radius: 0;
    }

    .sidebar {
      box-shadow: none;
      width: auto;
      min-width: auto;
      margin: 0;

      .summaryModule {
        border-radius: 0;
      }
    }
  }
}

.bgGrad {
  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 50%;
    @include vertical-gradient(#252E4D, #070B11);
  }
  position: relative;

}


.waves {
  width: 100%;
  height: 1041px;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 0;

  @include media-breakpoint-down(sm) {
    width: 200%;
    left: -100%;
    height: 800px;
  }
}
