@mixin horizontal-gradient($from, $to, $stop: 100%) {
  background-color: $from;
  background-image: -moz-linear-gradient(left, $from 0%, $to $stop);
  background-image: -webkit-gradient(linear, left top, right bottom, color-stop(0%, $from), color-stop($stop, $to));
  background-image: -webkit-linear-gradient(left, $from 0%, $to $stop);
  background-image: -o-linear-gradient(left, $from 0%, $to $stop);
  background: -ms-linear-gradient(left, $from 0%, $to $stop);
  background: linear-gradient(to right, $from 0%, $to $stop);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$from', endColorstr='$to', GradientType=1);
}

@mixin vertical-gradient($from, $to, $stop: 100%) {
  background-color: $from;
  background-image: -moz-linear-gradient(top, $from 0%, $to $stop);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $from), color-stop($stop, $to));
  background-image: -webkit-linear-gradient(top, $from 0%, $to $stop);
  background-image: -o-linear-gradient(top, $from 0%, $to $stop);
  background: -ms-linear-gradient(top, $from 0%, $to $stop);
  background: linear-gradient(to bottom, $from 0%, $to $stop);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$from', endColorstr='$to', GradientType=1);
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }
  &:-moz-placeholder {
    @content
  }
  &::-moz-placeholder {
    @content
  }
  &:-ms-input-placeholder {
    @content
  }
  &::placeholder {
    @content
  }
}

@mixin animation ($delay, $duration, $animation, $direction: forward, $fillmode: fowards) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-fill-mode: $fillmode;
  -webkit-animation-direction: $direction;

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-fill-mode: $fillmode;
  -moz-animation-direction: $direction;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: $fillmode;
  animation-direction: $direction;
}

@mixin anim-delay ($delay) {
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
  animation-delay: $delay;
}