@import "../../styles/base";

.priceModule {
  background-color: white;
  box-shadow: 0 4px 24px 0 rgba(0,0,0,0.05);
  border-radius: 4px;
  overflow: hidden;
  margin-left: 30px;
  height: auto;
  color: $darkText;
  padding: 40px 38px;
  box-sizing: border-box;

  @include media-breakpoint-down(md) {
    margin-left: 0;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 26px;
    font-weight: 600;
    margin: 0 0 16px;
  }

  .breakDownItem {
    border-bottom: solid 1px #EEEEEE;
    padding: 12px 0;
    color: $grayText;
    font-size: 14px;
    display: flex;
    justify-content: space-between;

    &:last-of-type {
      font-weight: 600;
      color: $darkText;
      border-bottom: 0;
      font-size: 16px;
      position: relative;
      &::after {
        content: 'Free U.S. shipping';
        position: absolute;
        right: 0;
        bottom: -5px;
        color: $grayText;
        font-style: italic;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .guarantee {
    background-color: rgba(206, 213, 251, 0.16);
    border: solid 1px #DFE2F0;
    border-radius: 4px;
    padding: 8px;
    text-align: center;
    margin-top: 25px;
    font-size: 16px;
    color: $grayText;
    font-weight: 500;

    button {
      border: 0;
      background-color: transparent;
      cursor: pointer;
      position: relative;
      top: 2px;
      &:focus {
        outline: none;
      }
    }
  }

  .orderNow {
    text-align: center;

    a {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}
