$green: #00FF85;
$yellow: #F5FC54;
$spRed: #D22121;
$brightRed: #FF4C4C;
$white: #ffffff;
$inputBg: #232626;
$background: #161818;
$whiteBackground: #EEEFF5;
$offWhite: #D8D8D8;
$lightGray: #C6C6C6;
$gray: #999999;
$clear: rgba(255, 255, 255, 0.0);
$whiteEight: rgba(255, 255, 255, 0.8);
$buttonFill: rgba(35,38,38,0.6);
$secondaryBorder: rgba(59,198,172, 0.53);
$secondaryText: #5DE5CC;
$blueGlow: rgba(39, 97, 233, 0.23);
$blue: #1B2A59;
$highlightBlue: #2761E9;
$paleBlue: #D7DAE0;
$pastelBlue: #7296DC;
$lightBlue: #ACCDE0;
$blackText: #1C1C1C;
$darkText: #494949;
$grayText: #797979;
