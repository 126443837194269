.main {
  display: block;
  width: 100%;
  background-color: white;
  box-shadow: 0 4px 24px 0 rgba(0,0,0,0.05);
  border-radius: 4px;
  overflow: hidden;
  padding: 30px 50px;
  box-sizing: border-box;
  transition: opacity 0.3s;
}

.verified {
  text-align: center;

  .vSubtitle {
    color: #797979;
    font-size: 24px;
    font-weight: 300;
    margin: 8px 0 2px;
  }

  .vTitle {
    color: #555C74;
    font-size: 24px;
    font-weight: 700;
  }
}

.title {
  font-weight: 700;
  color: #555C74;
  font-size: 24px;
  margin-bottom: 24px;
  margin-top: 18px;
}

.emailForm {
  display: flex;
  justify-content: space-between;

  button {
    margin: 3px 0 0 16px;
  }
}

.input {
  flex-grow: 1;
}
